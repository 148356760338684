import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Yhteydenotot:</h1>
    <p>
      Sähköposti:{` `}
      <a href="mailto:owrsftw@gmail.com">owrsftw@gmail.com</a>{" "}
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
  </Layout>
)

export default ContactPage
